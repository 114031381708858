import React, { forwardRef } from "react";

import SectionHeader from "../components/SectionHeader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default forwardRef(function About(props, aboutRoute) {
  var birthDate = Math.floor(
    (new Date() - new Date("2003-08-07")) / 31557600000
  );
  return (
    <section ref={aboutRoute} className="py-5">
      <Container>
        <SectionHeader opaque behindText="About Me">
          Learn About Me
        </SectionHeader>
        <Row>
          <Col lg={7} xl={8} className="text-center text-lg-start">
            <h2>
              I'm <span style={{ color: "#e04c4c" }}>Robert Gordiyevych,</span>{" "}
              a Developer
            </h2>
            <p className="text-white-50">
              From a young age I was fascinated by computers, everything from
              building, fixing, and programming them. I have spent countless
              hours messing with servers, running linux, and creating simple
              scripts with python. Currently, I specialize more on web
              development and recently took up app development for android and
              ios.
            </p>
            <p className="text-white-50">
              My goal is to help build your brand through websites and mobile
              apps for your business. Driven by boundless enthusiasm and passion
              for my work, across multiple mediums, as I seek new experiences
              and challenges.
            </p>
          </Col>
          <Col lg={5} xl={4}>
            <div style={{ paddingLeft: "1.5rem" }} className="align-start">
              <ul className="list-style align-start">
                <li>
                  <span className="fw-600 me-2">Name:</span> Robert Gordiyevych
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>{" "}
                  <span style={{ color: "#e04c4c" }}>
                    robert.gordiyevych@gmail.com
                  </span>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>
                  {birthDate}
                </li>
                <li style={{ border: 0 }}>
                  <span className="fw-600 me-2">From:</span>
                  Dallas, Georgia
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
});
