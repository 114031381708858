import React, { useRef } from "react";

import CollapsibleNavbar from "./CollapsibleNavbar";
import Footer from "./Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Resume from "./pages/Resume";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function App() {
  const topOfPage = useRef(null);
  const home = useRef(null);
  const about = useRef(null);
  const resume = useRef(null);
  const portfolio = useRef(null);
  const contact = useRef(null);

  const scrollToSection = (elementRef) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div id="mainWrapper" ref={topOfPage}>
      <CollapsibleNavbar
        scrollToSection={scrollToSection}
        home={home}
        about={about}
        resume={resume}
        portfolio={portfolio}
        contact={contact}
      />
      <div className="content">
        <Home
          ref={home}
          id="home"
          scrollToSection={scrollToSection}
          about={about}
        />
        <About ref={about} id="about" />
        <Resume ref={resume} id="resume" />
        <Portfolio ref={portfolio} id="portfolio" />
        <Contact ref={contact} id="contact" />
        <Footer />
      </div>
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip id="back-to-top">Back To Top</Tooltip>}
      >
        <button
          onClick={() => scrollToSection(topOfPage)}
          style={{
            position: "fixed",
            bottom: "5%",
            right: "5%",
            cursor: "pointer",
            borderRadius: "50%",
            border: "none",
          }}
        >
          <ArrowUpwardIcon fontSize="large" />
        </button>
      </OverlayTrigger>
    </div>
  );
}
