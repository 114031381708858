import React, { forwardRef } from "react";
import "../stylesheets/Home.css";
import TypeIt from "typeit-react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default forwardRef(function Home({ scrollToSection, about }, homeRoute) {
  return (
    <section ref={homeRoute} className="home-background">
      <div className="Content d-flex">
        <Container className="my-auto">
          <Row>
            <Col lg={12} className="text-center">
              <h1>Welcome</h1>
              <h1 className="biggerHeader d-flex justify-content-center">
                <TypeIt
                  options={{ loop: true }}
                  getBeforeInit={(instance) => {
                    return instance
                      .type(
                        `I'm <span style="color: #e04c4c">Robert Gordiyevych.</span>`
                      )
                      .pause(1500)
                      .delete(23)
                      .type(
                        `I'm a <span style="color: #e04c4c">Developer.</span>`
                      )
                      .pause(1500);
                  }}
                />
              </h1>
              <h4>Based in Dallas, Georgia</h4>
            </Col>
          </Row>
          <Row className="downArrow">
            <Col>
              <ExpandMoreIcon
                color="white"
                onClick={() => scrollToSection(about)}
                className="expandMoreIcon"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
});
