import React, { forwardRef } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SectionHeader from "../components/SectionHeader";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import "../stylesheets/Main.css";

export default forwardRef(function Portfolio(props, portfolioRoute) {
  return (
    <section ref={portfolioRoute} className="py-5">
      <Container>
        <SectionHeader opaque behindText="Portfolio">
          My Work
        </SectionHeader>
        <Row md={2} lg={3} className="justify-content-center">
          {/* Alpine Coffee Card */}
          <Card
            style={{ width: "20rem" }}
            className="p-0 m-2"
            bg="black"
            text="light"
            border="warning"
          >
            <Card.Img
              variant="top"
              src="/images/alpine-logo.png"
              style={{
                backgroundColor: "#343a40",
                height: "150px",
              }}
            />
            <Card.Header>
              <Card.Title id="card-title-extra">Alpine Coffee Inc</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                A new convenient way to serve coffee in the workplace with
                vending stands filled with many options to choose from.
              </Card.Text>
              <Button
                variant="primary"
                target="_blank"
                rel="noopener noreferrer"
                href="https://alpinecoffeeinc.com/"
              >
                Go to Website
              </Button>
            </Card.Body>
          </Card>
          {/* Sounders Trucking Card */}
          <Card
            style={{ width: "20rem" }}
            className="p-0 m-2"
            bg="black"
            text="light"
            border="secondary"
          >
            <Card.Img
              variant="top"
              src="/images/sounders-trucking.png"
              style={{
                backgroundColor: "#212529",
                height: "150px",
              }}
            />
            <Card.Header>
              <Card.Title id="card-title-extra">
                Sounders Trucking LLC
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                A family-owned business with the best shop for heavy duty truck
                and trailer repair in the Winston GA area.
              </Card.Text>
              <Button
                variant="primary"
                target="_blank"
                rel="noopener noreferrer"
                href="https://sounderstransport.com/"
              >
                Go to Website
              </Button>
            </Card.Body>
          </Card>
          {/* WeightLog App Card */}
          <Card
            style={{ width: "20rem" }}
            className="p-0 m-2"
            bg="black"
            text="light"
            border="danger"
          >
            <Card.Img
              variant="top"
              src="/images/weight-log-app.png"
              style={{
                backgroundColor: "#ca2c21ff",
                height: "150px",
              }}
            />
            <Card.Header>
              <Card.Title id="card-title-extra">WeightLog</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                A simple app for tracking your weight with graphs to aid you on
                your dieting journey.
              </Card.Text>
              <Button
                variant="primary"
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.robgordtech.weightlog"
              >
                Go to App Store
              </Button>
            </Card.Body>
          </Card>
          {/* Print(Notes) App Card */}
          <Card
            style={{ width: "20rem" }}
            className="p-0 m-2"
            bg="black"
            text="light"
            border="info"
          >
            <Card.Img
              variant="top"
              src="/images/printnotes-logo.png"
              style={{
                backgroundColor: "#4d8fff",
                height: "150px",
                objectFit: "contain",
              }}
            />
            <Card.Header>
              <Card.Title id="card-title-extra">Print(Notes)</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                A cross-platform notes app the uses Markdown to help organize
                notes, all stored locally on your device, as well has many
                themes to choose from with LaTeX math notation support
              </Card.Text>
              <Button
                variant="primary"
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/RoBoT095/printnotes"
              >
                Go to GitHub
              </Button>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </section>
  );
});
